<template>
  <div class="common-table">
    <el-table
      v-loading="config.loading"
      :data="tableData"
      border
      height="90%"
      highlight-current-row
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
      :row-class-name="tableRowClassName"
    >
      <el-table-column align="center" prop="code" label="编号" width="150"> </el-table-column>
      <el-table-column align="center" prop="date" label="日期" width="150"> </el-table-column>
      <el-table-column align="center" prop="yikukufang_name" label="由(A)库房" width="150"> </el-table-column>
      <el-table-column align="center" prop="jieshoukufang_name" label="到(B)库房" width="150"> </el-table-column>
      <el-table-column align="center" prop="cailiao_name" label="材料名称" width="100"> </el-table-column>
      <el-table-column align="center" prop="cailiaoguige_name" label="材料规格" width="100"> </el-table-column>
      <el-table-column align="center" prop="yikushu" label="数量（片）" width="100"> </el-table-column>
      <el-table-column align="center" prop="out_amount" label="已发出" width="100"> </el-table-column>
      <el-table-column align="center" prop="in_amount" label="剩余" width="100"> </el-table-column>
      <el-table-column align="center" prop="remark" label="备注" width="300"> </el-table-column>
      <el-table-column align="center" label="财务审核" width="100">
        <template slot-scope="scope">
          <el-button
            size="mini"
            plain
            :type="scope.row.is_caiwushenhe === true ? 'success' : 'primary'"
            @click="handleCaiwuAudit(scope.row)"
            :disabled="btnCaiwuDisable(scope.row)"
            >{{ scope.row.is_caiwushenhe ? '已审核' : '未审核' }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" label="库管确认" width="100">
        <template slot-scope="scope">
          <el-button
            size="mini"
            plain
            :type="scope.row.is_queren === true ? 'success' : 'primary'"
            @click="handleKuguanConfirm(scope.row)"
            :disabled="btnKuguanConfirmDisable(scope.row)"
            >{{ scope.row.is_queren ? '已确认' : '未确认' }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" label="管理" width="150">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-if="canDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pager"
      :page-size="pagesize"
      layout="prev, pager, next"
      :total="config.total"
      :current-page.sync="config.page"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
import utils from '@/assets/js/utils'

export default {
  data() {
    return {
      pagesize: 20
    }
  },
  props: {
    tableData: Array,
    config: Object,
    userType: Object
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.userType.wuzidiaopei_del) {
          if (this.userType.id === 1) return true
          else {
            let added_person_time = utils.stringToDate(row.added.split('\n')[1])
            let now = new Date()
            let interval = now.getTime() - added_person_time.getTime()
            interval = interval / 1000 / 60 / 60
            // console.log('addedtime:', added_person_time)
            // console.log('now:', now)
            // console.log('del..........', interval)
            if (interval > 2) return false
            else return true
          }
        } else return false
      }
    },
    btnCaiwuDisable() {
      return function(row) {
        if (this.userType.code == '7' && row.is_caiwushenhe === false) return false
        else return true
      }
    },

    btnKuguanConfirmDisable() {
      return function(row) {
        if (this.userType.cailiaotongzhidan_confirm === true && row.is_caiwushenhe === true && row.is_queren === false) return false
        else return true
      }
    }
  },
  methods: {
    //换页
    changePage(page) {
      this.$emit('changePage', page)
    },
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },
    //物资审核
    handleWuziAudit(row) {
      this.$emit('wuziAudit', row)
    },

    //财务审核
    handleCaiwuAudit(row) {
      this.$emit('caiwuAudit', row)
    },
    //库管确认
    handleKuguanConfirm(row) {
      this.$emit('kuguanConfirm', row)
    },

    //删除
    handleDelete(row) {
      this.$emit('del', row)
    },

    //设置小计和合计行的状态
    tableRowClassName({ row, rowIndex }) {
      // console.log(row)
      // console.log(rowIndex)
      if (row.type === '2') {
        return 'xiaoji-row'
      } else if (row.type === '3') {
        return 'heji-row'
      } else if (rowIndex % 2 === 0) {
        return 'stripe-row'
      }
      return 'normal-row'
    },

    /**
     * 表格合并,小计和合计列合并
     * @param {*} param0
     * row 表格每一行的数据
     * column 表格每一列的数据
     * rowIndex 表格的行索引,不包括表头,从0开始
     * columnIndex 表格的列索引,从0开始
     */
    arraySpanMethod({ row, columnIndex }) {
      // console.log(row)
      if (row.type === '2' || row.type === '3') {
        if (columnIndex === 3) {
          // 从第2列开始(首列为第0列)
          // 这里返回的是行和列的合并数量,可以返回一个数组,也可以返回一个对象,效果一样
          // 这里rowspan为1是行有一行合并,colspan为3是列有3列合并,你要合并几行几列就写上相应的数字
          // return {
          //    rowspan: 1,
          //    colspan: 3
          //  }
          return [1, 2]
          // 这个判断是把合并的第3列,第4列的值省略,在合并的表格右边直接填原先第5列的值,合并了几列,就省略几列的值
        } else if (columnIndex === 5) {
          return [1, 2]
        } else if (columnIndex === 4) {
          return [0, 0]
        }
      }
    }
  }
}
</script>

<style scoped></style>

<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
